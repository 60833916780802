/*
 * @Author: your name
 * @Date: 2024-03-27 09:10:34
 * @LastEditTime: 2024-08-30 14:37:08
 * @LastEditors: your name
 * @Description: 
 * @FilePath: \web_BullyingPrevention\src\http\moudules\webApi.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
import axios from '../axios'

//查询学校下的所有设备
export const getDevicesInfo = (data) => {
  return axios({
    url: '/fbullying/deviceList',
    method: 'post',
    data
  })
}
//查询学校下的所有位置
export const getSchoolLocation = (schoolId) => {
  return axios({
    url: `/fbullying/getLocationCode?schoolId=${schoolId}`,
    method: 'get',
  })
}
//通过sn查询设备信息
export const getInfoBySn = (sn,autoInsert) => {
  let insert = ''
  if(autoInsert){
    insert = '&autoInsert=true'
  }
    return axios({
      url: `/fbullying/getByMac?mac=${sn}${insert}`,
      method: 'get',
    })
}
//获取所有的事件列表
export const getEventList = (data) => {
    return axios({
      url: '/event/eventList',
      method: 'post',
      data
    })
}
//获取待合并事件列表
export const toMergeEventList = (data) => {
    return axios({
      url: '/event/toMergeEvent',
      method: 'post',
      data
    })
}
//合并事件
export const toMergeEvent = (data) => {
    return axios({
      url: '/event/merge',
      method: 'post',
      data
    })
}
//删除事件
export const deleteEvent = (eventId) => {
    return axios({
      url: `/event/delete?eventId=${eventId}`,
      method: 'get',
    })
}
//获取事件的详情
export const getEventDetail = (eventId) => {
    return axios({
      url: `/event?eventId=${eventId}`,
      method: 'get',
    })
}
//获取全校的教职工
export const getAllTeacher = (schoolId) => {
    return axios({
      url: `/user/userAll?schoolId=${schoolId}`,
      method: 'get',
    })
}
//处理事件
export const handleEvent = (data) => {
    return axios({
      url: '/event/deal',
      method: 'post',
      data
    })
}
//添加设备保存、更新设备
export const addDevices = (data) => {
  return axios({
    url: '/fbullying/save',
    method: 'post',
    data
  })
}
//设备统计
export const getAllDevices = (schoolId,locationId) => {
  return axios({
    url: `/fbullying/getDeviceCount?schoolId=${schoolId}&locationId=${locationId}`,
    method: 'get',
  })
}

//获取学校的响应时间
export const getAllEventTime = (schoolId,locationId,endTime) => {
  return axios({
    url: `event/getEventRespTimeStatistics?schoolId=${schoolId}&locationId=${locationId}&endTime=${endTime}`,
    method: 'get',
  })
}
//获取事件月份统计
export const getAllEventMonth = (schoolId,locationId,endTime) => {
  return axios({
    url: `/event/getEventStatistics?schoolId=${schoolId}&locationId=${locationId}&endTime=${endTime}`,
    method: 'get',
  })
}

//获取位置事件排行榜
export const getAllEventPosition = (schoolId,endTime) => {
  return axios({
    url: `/event/getLocationEventStatistics?schoolId=${schoolId}&endTime=${endTime}`,
    method: 'get',
  })
}

//操作记录 
export const operationRecords = (id,type) => {
  let data =  {
    eventId:id,
    type:type
  }
  return axios({
    url: '/event/record/add',
    method: 'post',
    data
  })
}

//获取分机号
export const getPhoneNumber = () => {
  return axios({
    url: `/phone/getDynamicNumber`,
    method: 'get',
  })
}
//更新号码状态
export const gupdatePhoneNumber = (phoneNo,targetNo,deviceId) => {
  return axios({
    url: `/phone/update?phoneNo=${phoneNo}&targetNo=${targetNo}&deviceId=${deviceId}`,
    method: 'get',
  })
}

// 获取所有的分机
export const getPhoneList = () => {
  return axios({
    url: `/phone/getPhoneList`,
    method: 'get',
  })
}
//创建一个分机 
export const createExtension = (data) => {
  return axios({
    url: '/phone/createExtension',
    method: 'post',
    data
  })
}
//批量创建分机
export const createExtensionBath = (data) => {
  return axios({
    url: '/phone/createExtensionBath',
    method: 'post',
    data
  })
}
//属性配置
export const applyConfig = () => {
  return axios({
    url: '/phone/applyConfig',
    method: 'post',
  })
}
//更新分机密码
export const updateExtension = (data) => {
  return axios({
    url: '/phone/updateExtension',
    method: 'post',
    data
  })
}
//删除分机
export const deleteExtension = (data) => {
  return axios({
    url: '/phone/deleteExtension',
    method: 'post',
    data
  })
}
//OTA升级
export const otaBulling = (data) => {
  return axios({
    url: '/fbullying/ota',
    method: 'post',
    data
  })
}
//设置SIP | 设置关键字
export const sipConfig = (sn,data) => {
  return axios({
    url: `/fbullying/${sn}/config`,
    method: 'post',
    data
  })
}

//刷新配置
export const fbullying = (sn) => {
  return axios({
    url: `fbullying/${sn}/refresh`,
    method: 'get',
  })
}
//删除设备
export const deleteDevices = (id) => {
  return axios({
    url: `fbullying/delete?id=${id}`,
    method: 'get',
  })
}

//获取token
export const getToken = (data) => {
  return axios({
    url: `/user/createToken`,
    method: 'post',
    data
  })
}
//批量删除事件
export const fetchDelete = (eventIds) => {
  return axios({
    url: `/event/fetchDelete?eventIds=${eventIds}`,
    method: 'get',
  })
}
//报警设置脏话是否记录为告警事件
export const setBadRecord = (data) => {
  return axios({
    url: `fbullying/setBadAlarm`,
    method: 'post',
    data
  })
}
//导出事件记录
export const getallEventExport = (data) => {
  return axios({
    url: '/event/export',
    method: 'post',
    data
  })
}
// 登录
// export const login = (data) => {
//   return axios({
//     url: "/qz/login",
//     method: "post",
//     data,
//   });
// };
//定时刷新token
export const getNewToken = () => {
  return axios({
    url: `/user/refreshToken`,
    method: 'get',
  })
}

//重启设备
export const restart = (sn) => {
  return axios({
    url: `fbullying/${sn}/restart`,
    method: 'get',
  })
}
//批量报警词设置 fbullying/configBatch
export const configBatch = (data) => {
  return axios({
    url: '/fbullying/configBatch',
    method: 'post',
    data
  })
}
//批量设备设置sip  fbullying/configBatch
export const ipConfigBatch = (data) => {
  return axios({
    url: '/fbullying/configBatch',
    method: 'post',
    data
  })
}
//自动获取username和密码 autoGetUsername
export const autoGetUsername = () => {
  return axios({
    url: 'fbullying/getOnePhone',
    method: 'post'
  })
}